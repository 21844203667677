import { Loader } from "pixi.js";
import { funct as markers_manager } from './markers'
import { funct as poly_manager } from './polygons'

function load_assets(loader) {
    loader.add('m_blue-pushpin',     require('@/assets/kml/blue-pushpin.png'));
    loader.add('m_grn-pushpin',      require('@/assets/kml/grn-pushpin.png'));
    loader.add('m_ltblu-pushpin',    require('@/assets/kml/ltblu-pushpin.png'));
    loader.add('m_pink-pushpin',     require('@/assets/kml/pink-pushpin.png'));
    loader.add('m_purple-pushpin',   require('@/assets/kml/purple-pushpin.png'));
    loader.add('m_red-pushpin',      require('@/assets/kml/red-pushpin.png'));
    loader.add('m_wht-pushpin',      require('@/assets/kml/wht-pushpin.png'));
    loader.add('m_ylw-pushpin',      require('@/assets/kml/ylw-pushpin.png'));
    return loader;
}

function create_layer(name, markers, polygons) {
    return {
        name:               name,
        markers:            markers,
        polygons:           polygons,
        visible:            false
    };
}

function prepare_markers(res, features) {
    var markers = [];
    features.forEach(feature => {
        if (feature.type == 'Point') {
            markers.push(markers_manager.create(res, feature.coordinates));
        } else if(feature.type == 'Feature') {
            if (feature.geometry.type == 'Point') {
                markers.push(markers_manager.create(res, feature.geometry.coordinates, feature.properties));
            } else if (feature.geometry.type == 'GeometryCollection') {
                var more = prepare_markers(res, feature.geometry.geometries);
                markers = [...markers, ...more];
            }
        } else if (feature.type == 'FeatureCollection') {
            var more = prepare_markers(res, feature.features);
            markers = [...markers, ...more];
        }
    });
    return markers;
}

function prepare_polygons(features) {
    var polygons = [];
    features.forEach(feature => {
        if (feature.type == 'Polygon') {
            polygons.push(poly_manager.create(feature.coordinates));
        } else if (feature.type == 'LineString') {
            polygons.push(poly_manager.create(feature.coordinates, null, "Line"));
        } else if(feature.type == 'Feature') {
            if (feature.geometry.type == 'Polygon') {
                polygons.push(poly_manager.create(feature.geometry.coordinates, feature.properties));
            } else if (feature.geometry.type == 'LineString') {
                polygons.push(poly_manager.create(feature.geometry.coordinates, feature.properties, "Line"));
            } else if (feature.geometry.type == 'GeometryCollection') {
                var more = prepare_polygons(feature.geometry.geometries);
                polygons = [...polygons, ...more];
            }
        } else if (feature.type == 'FeatureCollection') {
            var more = prepare_polygons(feature.features);
            polygons = [...polygons, ...more];
        }
    });

    return polygons;
}

function layer_from_json(resources, name, geojson, leaflet_map) {
    var layer;
    var polygons    = prepare_polygons(geojson.features);
    var markers     = prepare_markers(resources, geojson.features, leaflet_map);
    layer           = create_layer(name, markers, polygons);

    return layer;
}

function process_input_from_layer(tree, layer) {
    for(const poly of layer.polygons) {
        
        if(poly.type == "Line") continue;
        if(!poly.properties) continue;

        var bounds = L.bounds(poly.data[0]);

        var entry = {
            minX: bounds.min.x,
            minY: bounds.min.y,
            maxX: bounds.max.x,
            maxY: bounds.max.y,
            feature: poly
        }
        tree.insert(entry);
    }

    for(const m of layer.markers) {
        if(!m.properties) continue;
        var bounds = m.point;

        var entry = {
            minX: bounds[0] - .0001,
            minY: bounds[1] - .0001,
            maxX: bounds[0] + .0001,
            maxY: bounds[1] + .0001,
            feature: { type: "Point", ...m }
        }

        tree.insert(entry);
    }

    return tree;
}

export const manage = { 
    load_assets,
    layer_from_json,
    process_input_from_layer
}