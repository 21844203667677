<template>
    <div>
        <v-row v-if="$parent.$parent.get_permission('show_evaluador')">
            <v-col cols="12">
                <DashCard title="Instrucción">
                    <v-label>Marque un polígono en el lugar que desee para verificar si es posible realizar una intervención en la zona seleccionada.</v-label>
                </DashCard>
            </v-col>
            <v-col cols="12">
                <MapCardV2 ref="mapa" :satellite="true" :interactive="true" :editable="true" height="640" />
            </v-col>
            <v-col>
                <v-alert v-if="result" :type="result.type">
                    {{ result.text }}
                </v-alert>
                <DashCard body-class="text-right pa-3">
                    <template v-if="this.$parent.$parent.get_permission('edit')">
                        <v-btn class="ma-1" v-if="result && result.type=='success'" 
                            large depressed color="secondary" @click="crear_nueva_solicitud">
                            <v-icon left>edit</v-icon>
                            <span class="subtitle-2">Crear Solicitud</span>
                        </v-btn>
                    </template>
                    <v-btn class="ma-1" large depressed color="primary" @click="verificar">
                        <span class="subtitle-2">Verificar</span>
                    </v-btn>
                </DashCard>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col>
                <AccessMessage />
            </v-col>
        </v-row>
        <Message ref="dialog" />
    </div>
</template>

<script>
import { axiosHelper, messages } from '@/helpers'
import MapCardV2 from '@/dash/components/MapCardV2'
import DashCard from '@/shared/components/DashCard'
import Message from '@/shared/components/Message'
import AccessMessage from '@/dash/components/AccessMessage'

export default {
    components: {
        MapCardV2,
        DashCard,
        AccessMessage,
        Message
    },
    data: () => ({
        capas: [],
        result: null
    }),
    methods: {
        load_capas() {
            const mapa = this.$refs.mapa;
            axiosHelper.get('evaluador/')
                .then(response => {
                    this.capas = response.data;
                })
                .then(() => {
                    mapa.set_busy(true);
                    this.capas.forEach(c => mapa.push_layer(c.name, c));
                    mapa.process_layers();
                })
                .then(() => {
                    mapa.refresh();
                    mapa.set_busy(false);
                });
        },
        crear_nueva_solicitud() {
            let geo = this.$refs.mapa.get_editor_result();
            this.$router.push({ name: 'dashboard_sias_create', params: { geo } });
        },
        success() {
            this.result = {
                "type": "success",
                "text": messages.texts["zone_evaluator_success"]
            };
        },
        error() {
            this.result = {
                "type": "error",
                "text": messages.texts["zone_evaluator_error"]
            };
        },
        verificar() {
            this.result = null;
            let geo = this.$refs.mapa.get_editor_result();

            if(geo.features.length < 1) {
                geo = null;
            }

            axiosHelper.post('evaluador/', { input: geo })
                .then(response => {
                    if(response.data.valid_area) {
                        this.success();
                    } else {
                        this.error();
                    }
                })
                .catch(error => {
                    this.$refs.dialog.show(messages.titles["error_generico"], error.response.data.detail || messages.texts["error_desconocido"], "error");
                });
        }
    },
    mounted() {
        this.$emit('title', { text: "Evaluador de Áreas", icon: "done_all" });
        
        if(!this.$parent.$parent.get_permission('show_evaluador')) return;
        this.$nextTick(this.load_capas);
    }
}
</script>