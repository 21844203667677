import { Polygon, Point, Graphics } from "pixi.js";

function create(poly, properties, type) {
    return { 
        type: type || "Polygon", 
        data: poly, 
        properties: properties, 
        ready: false,
        graphics: new Graphics()
    };
}

function render(utils, container, polygon) {
    var project = utils.latLngToLayerPoint;

    if(polygon.ready) return;
    polygon.graphics.clear();
        
    if(polygon.type == "Polygon") {
        polygon.shape = new Polygon(polygon.data[0].map(point => {
            var proj = project([point[1], point[0]]);
            return new Point(proj.x, proj.y);
        }));
        
        if(!!polygon.properties) {
            polygon.shape.popup = polygon.properties;
            polygon.shape.interactive = true;
        }

        polygon.graphics.beginFill(0xff0000, 0.5);
        polygon.graphics.drawPolygon(polygon.shape);
        polygon.graphics.endFill();

    } else if(polygon.type == "Line") {
        polygon.data = polygon.data.map(p => project([p[1], p[0]]));
        var scale = utils.getScale();
    
        if (polygon.type == "Line") {
            var points = polygon.data;
            polygon.graphics.lineStyle(5 / scale, 0x2453ff);
    
            polygon.graphics.x = points[0].x;
            polygon.graphics.y = points[0].y;
    
            points.forEach((point, i) => {
                if(i == 0) polygon.graphics.moveTo(0, 0);
                else polygon.graphics.lineTo(point.x - polygon.graphics.x, point.y - polygon.graphics.y);
            });
        }
    }

    polygon.ready = true;
    container.addChild(polygon.graphics);
}


export const funct = {
    create,
    render
}