import { funct as marker_manager } from './markers'
import { funct as poly_manager } from './polygons'

function draw_polygon(utils, container, poly) {
    poly_manager.render(utils, container, poly);
}

function draw_marker(utils, container, marker) {
    marker_manager.update(utils, container, marker);
    marker_manager.render(utils, marker);
}

function render_markers(utils, layer) {
    var container = utils.getContainer();
    layer.markers.forEach(marker => draw_marker(utils, container, marker));
}

function render_polygons(utils, layer) {
    var container = utils.getContainer();
    layer.polygons.forEach(poly => draw_polygon(utils, container, poly));
}

export const render = {
    render_markers,
    render_polygons
}