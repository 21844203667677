<template>
    <div>
        <v-card tile :height="height">
            <l-map ref="map" :zoom="zoom" :center="center" style="z-index: 1 !important;">
                <template v-if="satellite">
                    <v-tilelayer-googlemutant lang="es-CL" :options="{ type: 'hybrid' }" />
                </template>
                <template v-else>
                    <l-tile-layer :url="tile_layer_url" />
                </template>
            </l-map>
            <v-overlay v-model="busy" absolute dark color="primary" :opacity="0.85">
                <div class="text-center">
                    <v-icon class="d-block" size="64" color="white">hourglass_empty</v-icon>
                    <span class="d-blocksubtitle-1">{{ busy_status }}</span>
                </div>
            </v-overlay>
        </v-card>
        <v-dialog dark v-model="info.visible" max-width="800" transition="fade-transition">
            <v-card>
                <v-card-title class="pa-0 ma-0 subtitle-1 red" flat tile outlined>
                    <v-row no-gutters class="ma-0">
                        <v-col class="pa-1 flex-grow-1 flex-shrink-0 my-auto" align="center">Propiedades del elemento</v-col>
                        <v-col class="pa-1 flex-grow-0 flex-shrink-1">
                            <v-btn icon @click="info.visible = false"><v-icon>close</v-icon></v-btn>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-divider />
                <v-card flat class="overflow-y-auto" max-height="500px">
                <v-simple-table dense>
                    <template v-slot:default>
                        <tbody>
                            <tr v-for="(val, key) in info.data" :key="key">
                                <td>{{ key }}</td>
                                <td width="500">
                                    <template v-if="val.length > 100">
                                        <v-textarea class="body-2" hide-details readonly outlined solo dense :value="val" :max-height="100" />
                                    </template>
                                    <template v-else>
                                        {{ val }}
                                    </template>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                </v-card>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { LMap, LTileLayer } from 'vue2-leaflet'
import { KMZParser }  from 'leaflet-kmz'
import LDraw from 'leaflet-draw';

import Vue2LeafletGoogleMutant from 'vue2-leaflet-googlemutant';

import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';

import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen';

import RBush from "rbush"
import { Container, Graphics, Polygon, Point, Loader, Sprite, Mesh } from "pixi.js";
import "leaflet-pixi-overlay";

import { piximap } from '@/helpers/piximap'

export default {
    props: ['height', 'editable', 'interactive', 'satellite'],
    components:
    { 
        LMap,
        LTileLayer,
        'v-tilelayer-googlemutant': Vue2LeafletGoogleMutant
    },
    data: () => ({
        info: {
            visible: false,
            data: null
        },
        edit_layer: null,
        editor_result: null,
        raw_layers: [],
        layers: [],
        loader: null,
        container: null,
        overlay: null,
        firstDraw: false,
        busy: true,
        busy_status: "",
        input_tree: new RBush(),
        prevZoom: 0,
        zoom: 18,
        center: L.latLng(-31.8226285,-70.5819457),
        tile_layer_url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    }),
    beforeMount() {
        this.loader = new Loader();
        this.loader = piximap.manage.load_assets(this.loader);
    },
    mounted() {
        const map = this.$refs.map.mapObject;
        map.addControl(new window.L.Control.Fullscreen());
        this.container = new Container();
        this.container.interactive = true;
        this.container.interactiveChildren = false;
        this.container.buttonMode = false;

        var doubleBuffering = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    
        this.loader.load((loader, resources) => {
            this.overlay = L.pixiOverlay(utils => {
                    this.firstDraw = true;
                    this.draw(utils, loader, resources);
                }, 
                this.container, 
                {
                    autoPreventDefault: false,
                    doubleBuffering: doubleBuffering,
                });
            
            this.overlay.addTo(map);
        });

        if(!!this.editable) {
            this.edit_layer = new window.L.FeatureGroup();
            
            this.$nextTick(() => {
                const drawControl = new window.L.Control.Draw({
                    position: 'topleft',
                    draw: {
                        polyline: false,
                        polygon: true,
                        rectangle: true,
                        circle: false,
                        circlemarker: false,
                        marker: true
                    },
                    edit: {
                        featureGroup: this.edit_layer,
                        poly: {
                            allowIntersection: true
                        }
                    }
                });
                map.addLayer(this.edit_layer);
                map.addControl(drawControl);
                
                map.on('draw:created', (e) => {
                    const layer = e.layer;
                    this.edit_layer.addLayer(layer);
                    this.update_editable_json();
                });

                map.on('draw:edited', (e) => {
                    const layer = e.layer;
                    this.update_editable_json();
                });

                map.on('draw:deleted', (e) => {
                    const layer = e.layer;
                    this.edit_layer.removeLayer(layer);
                    this.update_editable_json();
                });

            });
        }
    },
    methods: {
        set_busy(state) {
            this.busy = state;
        },
        update_editable_json() {
            this.editor_result = this.edit_layer.toGeoJSON();
        },
        get_editor_result() {
            this.update_editable_json();
            return this.editor_result;
        },
        show_meta(metadata) {
            this.info.visible = true;
            this.info.data = metadata;
            if(!!this.info.data.styleUrl)
                delete this.info.data.styleUrl;

            if(!!this.info.data.ORIGEN_FUENTE_DE_DATOS)
                delete this.info.data.ORIGEN_FUENTE_DE_DATOS;
        },
        push_layer(name, json) {
            this.raw_layers.push({ name: name, data: json });
        },
        process_layers() {
            const map = this.$refs.map.mapObject;
            this.busy = true;

            this.loader.load((loader, resources) => {
                for(const layer of this.raw_layers) {
                    var pixi_layer = piximap.manage.layer_from_json(resources, layer.name, layer.data, map);
                    this.layers.push(pixi_layer);
                    this.input_tree = piximap.manage.process_input_from_layer(this.input_tree, pixi_layer);
                }
            })
        },
        draw(utils, loader, resources) {
            var container = utils.getContainer();
            var renderer = utils.getRenderer();
            var zoom = utils.getMap().getZoom();
            var map = utils.getMap();
            
            if (this.firstDraw) {
                console.log("FIRST DRAW");

                for(const layer of this.layers) {
                    piximap.render.render_polygons(utils, layer);
                }

                for(const layer of this.layers) {
                    piximap.render.render_markers(utils, layer);
                }

                map.off('click');
                map.on('click', (e) => this.click_event(e, utils));
            }

            this.firstDraw = false;
            this.prevZoom = zoom;
            renderer.render(container);
        },
        click_event(e, utils) {
            var point = e.latlng;
            var entry = {
                minX: point.lng,
                minY: point.lat,
                maxX: point.lng,
                maxY: point.lat
            };

            var features = this.input_tree.search(entry);

            features.forEach((feat, item) => {
                this.show_meta(feat.feature.properties);
            });
        },
        refresh() {
            const map = this.$refs.map.mapObject;
            console.log("Updating");
            map._onResize();
        }
    }
}
</script>