import RBush from "rbush";
import 'leaflet'

function map_onclick(utils, onclick, tree) {
    var map = utils.getMap();
    map.off('click');

    map.on('click', function(e) {
        var feat = find_feature(utils, e.latlng, tree);
        if(!!feat && !!feat.properties) {
            onclick(feat.properties);
        }
    });
}

export const input = {
    map_onclick
}