import { Loader, Sprite } from "pixi.js";

function create(res, point, properties) {
    var texture = res['m_ylw-pushpin'].texture;
    
    if(!!properties) {
        if(!!properties.styleUrl) {
            var marker_style_id = properties.styleUrl.replace('#', '');
            try {
                texture = res[marker_style_id].texture;
            } catch(e) {
                //console.log("No se pudo asociar el estilo: " + marker_style_id);
            }
        }
    }
    var marker = new Sprite(texture);
    marker.anchor.set(0.25, 1);
    
    return { sprite: marker, properties: properties, point: point, ready: false };
}

function update(utils, container, marker) {
    if(marker.ready) return;
    
    var project = utils.latLngToLayerPoint;
    var center = project([marker.point[1], marker.point[0]]);
    
    marker.sprite.x = center.x;
    marker.sprite.y = center.y;

    container.addChild(marker.sprite);
    marker.ready = true;
}

function render(utils, marker) {
    var scale = utils.getScale();
    marker.sprite.scale.set(0.55 / scale);
    marker.sprite.currentScale = 1 / scale;
}

export const funct = {
    create,
    update,
    render
}